import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import Person from '@/components/People/Person';

class PeopleFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentlyOpen: false,
      prevOpen: false,
      order: this.props.team,
    };
    this.handlePersonClick = this.handlePersonClick.bind(this);
    this.setOpen = this.setOpen.bind(this);
  }

  handlePersonClick(id) {
    if (this.state.currentlyOpen) {
      this.setState(
        {
          currentlyOpen: false,
        },
        () => {
          setTimeout(() => {
            this.setOpen(id);
          }, 300);
        },
      );
    } else {
      this.setOpen(id);
    }
  }

  setOpen(id) {
    this.setState({
      currentlyOpen: id || false,
    });
  }

  render() {
    const { data } = this.props;
    const { edges: people } = data.allMarkdownRemark;
    const ordered = this.state.order;
    return (
      <>
        {ordered ? (
          <div className='feed feed--15 feed--rel'>
            {ordered &&
              ordered.map((person_name, i) => {
                let person = people.find(
                  (el) => el.node.frontmatter.title === person_name,
                );

                if (person !== undefined) {
                  return (
                    <Person
                      person={person.node}
                      key={person.node.id}
                      visible={person.node.id === this.state.currentlyOpen}
                      index={i}
                      handlePersonClick={this.handlePersonClick}
                    />
                  );
                } else {
                  return '';
                }
              })}
          </div>
        ) : (
          <div className='feed feed--15 feed--rel'>
            {people &&
              people.map(({ node: person }, i) => (
                <Person
                  person={person}
                  key={person.id}
                  visible={person.id === this.state.currentlyOpen}
                  index={i}
                  handlePersonClick={this.handlePersonClick}
                />
              ))}
          </div>
        )}
      </>
    );
  }
}

PeopleFeed.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default ({ team }) => (
  <StaticQuery
    team={team}
    query={graphql`
      query PeopleFeedQuery {
        allMarkdownRemark(filter: { frontmatter: { type: { eq: "person" } } }) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                role
                description
                hover_image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      quality: 45
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      quality: 45
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, props) => <PeopleFeed data={data} team={team} />}
  />
);
