import React, { Component } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';
import Collapse from '@kunukn/react-collapse';

export default class Person extends Component {
  render() {
    const person = this.props.person;

    return (
      <section
        class='person-item'
        data-aos='fade-up'
        data-aos-delay={100 * this.props.index}>
        <article
          className={this.props.visible ? 'person person--active' : 'person'}
          onClick={() =>
            this.props.handlePersonClick(this.props.visible ? false : person.id)
          }>
          <div className='person__wrapper'>
            {person.frontmatter.image ? (
              <div className='person__headshot'>
                <GatsbyImage
                  image={getImage(person.frontmatter.image)}
                  className={
                    person.frontmatter.hover_image
                      ? 'image image--initial'
                      : 'image'
                  }
                />
                {person.frontmatter.hover_image && (
                  <GatsbyImage
                    image={getImage(person.frontmatter.hover_image)}
                    className='image image--hovered'
                  />
                )}
              </div>
            ) : (
              ''
            )}
            <header className='person__header'>
              <h1 className='title-4'>{person.frontmatter.title}</h1>
              <h2 className='title-6 text-accent'>{person.frontmatter.role}</h2>
            </header>
          </div>
        </article>
        <Collapse
          className='person-details__wrapper'
          isOpen={this.props.visible}>
          <article className='person-details'>
            <div className='cols'>
              <header className='col mr-a'>
                <h1 className='title-4'>{person.frontmatter.title}</h1>
                <h2 className='title-6 text-accent'>
                  {person.frontmatter.role}
                </h2>
              </header>
              <ReactMarkdown
                children={person.frontmatter.description}
                className='person__description content'
              />
            </div>
          </article>
        </Collapse>
      </section>
    );
  }
}
