import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import SEO from '@/helpers/SEOHelper.js'

import Layout from '@/components/Layout'
import PeopleFeed from '@/components/People/PeopleFeed'

export const PeoplePageTemplate = ({ hero, image, hoverImage, team }) => {
  return (
    <>
      <section className='hero hero--bottom' style={{ padding: '80px 0' }}>
        {image && (
          <GatsbyImage image={getImage(image)} className='background' />
        )}
        <div className='hero__wrapper' style={{ padding: '40px 0' }}>
          <div className='container'>
            <div className='cols cols--center'>
              <div y={['90px', '90px']} className='col-50'>
                <h2 className='title-6 text-accent'>{hero.subheading}</h2>
                <h1 className='hero__title'>{hero.heading}</h1>
                <ReactMarkdown
                  children={hero.description}
                  className='hero__content'
                />
              </div>
              <div className='col-50'>
                <div className='hero__hover'>
                  <GatsbyImage
                    image={getImage(hoverImage.initial)}
                    className='image image--initial'
                  />
                  <GatsbyImage
                    image={getImage(hoverImage.hovered)}
                    className='image image--hovered'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='people'>
        <div className='container'>
          <PeopleFeed team={team} />
        </div>
      </section>
    </>
  )
}

const PeoplePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const seo = frontmatter.seo
  return (
    <Layout>
      <Helmet>
        <meta name='description' content={SEO.description(seo.description)} />
        <meta property='og:title' content={SEO.title(seo.title)} />
        <meta
          property='og:description'
          content={SEO.description(seo.description)}
        />
        <meta property='og:image' content={SEO.image(seo?.image?.publicURL)} />
        <title>{SEO.title(seo.title)}</title>
      </Helmet>
      <PeoplePageTemplate
        hero={frontmatter.hero}
        image={frontmatter.image}
        team={frontmatter.team}
        hoverImage={frontmatter.hover}
      />
    </Layout>
  )
}

PeoplePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default PeoplePage

export const pageQuery = graphql`
  query PeoplePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "people" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 2000
              quality: 45
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        hover {
          hovered {
            childImageSharp {
              gatsbyImageData(
                width: 1200
                quality: 45
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          initial {
            childImageSharp {
              gatsbyImageData(
                width: 1200
                quality: 45
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        team
        hero {
          subheading
          heading
          description
        }
        seo {
          title
          description
          image {
            publicURL
          }
        }
      }
    }
  }
`
